require('./bootstrap');

//TyppyJs CSS
//import { animateFill } from 'tippy.js';   // Viene importato in bootstrap.js
import 'tippy.js/dist/tippy.css';
import 'tippy.js/dist/backdrop.css';
import 'tippy.js/animations/shift-away.css';
import 'tippy.js/animations/scale.css';
import 'tippy.js/animations/scale-subtle.css';
import 'tippy.js/animations/scale-extreme.css';
import 'tippy.js/themes/material.css';


// AOS CSS Animator
import AOS from 'aos';
import 'aos/dist/aos.css';

AOS.init();

// Swiper
// import Swiper bundle with all modules installed
import Swiper from 'swiper/bundle';
// import styles bundle
import 'swiper/css/bundle';
window.Swiper = Swiper;
